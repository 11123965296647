
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";

import Form from "@/components/UI/form/Form.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Input from "@/components/UI/form/Input.vue";
import Select from "@/components/UI/form/Select.vue";
import TextArea from "@/components/UI/form/TextArea.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import InputRadio from "@/components/UI/form/InputRadio.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";
import FormItem from "@/components/UI/form/FormItem.vue";
import Button from "@/components/UI/Button.vue";
import InputSwitch from "@/components/UI/form/InputSwitch.vue";
import Comments from "@/components/Comments.vue";
import ModalWithFields from "@/components/ModalWithFields.vue";
import FnsExistingCertList from "@/components/FnsExistingCertList.vue";
import FnsRefreshButton from "@/components/FnsRefreshButton.vue";
import { STATUSES, PAYER_TYPES, PAYMENT_STATUSES } from "@/store/modules/application/application.constants";
import { DEFAULT_FORM_CONTROLS } from "@/store/modules/application/application.defaults";
import { IFieldsFromBasisApi, IFilialInfo } from "@/store/modules/application/application.interfaces";
import { ORGANIZATION_TYPES } from "@/constants/organization";


@Component({
  computed: {
    ...mapState("application", [
      "orderId",
      "paymentStatusId",
      "formControls",
      "optionsCountry",
      "optionsRegion",
      "optionsIdentificationKind",
      "optionsIdentificationDocumentTypes",
      "isEdit",
      "isEditManagerComment",
      "creationDate",
      "changeDate",
      "externalSourcePkcs10",
      "archived",
      "isManagerCommentEditable",
      "smevChecks",
      "fieldsFromBasisApi",
      "statusId",
      "errorGuidId",
      "filialClientLink",
      "isPdnUsingProhibited",
      "filialInfo",
    ]),
    ...mapGetters("application", [
      "getVisibleBodyForm",
      "getApplicationInfo",
      "getIsSystemProcessing",
      "getIsFormValid",
      "getFnsExistingCerts",
      "getPayerTypeOptions",
      "getIsReadonlyKeys",
      "getAddressFNS",
      "getShowFilialClientLink"
    ]),
  },
  methods: {
    ...mapActions("application", [
      "getReferenceCountries",
      "getReferenceRegions",
      "getReferenceIdentificationDocumentTypes",
      "inputHandler",
      "managerCommentSubmitHandler",
      "rejectManagerComment",
      "typeSelectionHandler",
      "nextActiveTab",
      "checkValidityAndScroll",
      "fillFormWithFile",
      "changeIsDirector",
      "changeForInfoSys",
      "refreshSmevStatus",
      "fetchApplicationFromBasisApi",
      "fillFormWithBasisApi",
      "refreshFnsStatus",
      "defineActivePayerFileds",
      "setFormControlProps",
      "fetchQrPayment"
    ])
  },
  components: {
    Form,
    FormBox,
    Input,
    TextArea,
    InputRadio,
    Select,
    FormItem,
    Checkbox,
    Button,
    IconBase,
    Comp,
    Usb,
    Archive,
    InputSwitch,
    Comments,
    ModalWithFields,
    FnsExistingCertList,
    FnsRefreshButton
  }
})
export default class ProposalForm extends Vue {

  public get isIconStatus() {
    return this.externalSourcePkcs10 || this.archived;
  }

  public get addresTitle() {
    if (this.formControls.typeEntre.value === "ur") {
      return "Юридический адрес";
    }
    return "Фактический адрес";
  }

  public get getDirectorsFromBasisApi() {
    const directors = this.fieldsFromBasisApi.directors || [];

    return directors.map((item, index) => (
      {value: index, label: `${item.surnameOrg} ${item.nameOrg} ${item.patronymicOrg}`}
    ));
  }

  public getReferenceCountries!: () => void;
  public getReferenceRegions!: () => void;
  public getReferenceIdentificationDocumentTypes!: () => void;
  public getProducts!: () => void;
  public formControls!: any;
  public isEdit!: null | boolean;
  public checkValidityAndScroll!: () => void;
  public nextActiveTab!: (secrollUp: boolean) => void;
  public editManagerComment!: () => void;
  private externalSourcePkcs10!: boolean;
  private archived!: boolean;
  private changeIsDirector!: ({
    checked
  }: {
    checked: boolean;
  }) => void;
  private refreshSmevStatus!: ({}) => void;
  public getIsFormValid!: boolean;
  public showTooltip?: boolean = false;
  public typeSelectionHandler!: (typeEntre: string) => void;
  private typeEntre: string = "";
  private fieldsFromBasisApi!: IFieldsFromBasisApi;
  public statusId!: number;
  public orderId!: number;
  private DEFAULT_FORM_CONTROLS = DEFAULT_FORM_CONTROLS;
  private PAYER_TYPES = PAYER_TYPES;
  public inputHandler!: (evt: any) => void;
  public paymentStatusId!: number;
  public isPdnUsingProhibited!: boolean;
  public filialInfo!: IFilialInfo;

  private get showFnsRefreshButton() {
    return this.formControls.typeEntre.value !== "fl"
      && (this.statusId === STATUSES.DocumentsSending.id
      || this.statusId === STATUSES.CertificateRequest.id);
  }

  private get showPaymentBtn() {
    return (this.paymentStatusId!== PAYMENT_STATUSES.Paid.id) && (this.statusId === STATUSES.DocumentsSending.id
      || this.statusId === STATUSES.CertificateRequest.id
      || this.statusId === STATUSES.Moderation.id
      || this.statusId === STATUSES.CertificateIssue.id) &&
      (this.filialInfo?.organizationType === ORGANIZATION_TYPES.Agential); // только для агентского филиала
  }

  public get isFieldValid() {
    return (isValid: boolean) => {
      return this.isPdnUsingProhibited || isValid;
    }
  }
  
  public mounted() {
    this.getReferenceRegions();
    this.getReferenceCountries();
    this.getReferenceIdentificationDocumentTypes();
  }

  private handleRefreshSMEV(fieldName: string) {
    const SMEV_REQUEST_TYPES = [
      {name: "СНИЛС", value: 0},
      {name: "ИНН", value: 1},
      {name: "Паспорт", value: 2},
      {name: "ОГРНИП", value: 3},
      {name: "ОГРН", value: 4},
    ];
    const applicationId = this.orderId;
    const smevRequestType = SMEV_REQUEST_TYPES
      .find((type: any) => type.name == fieldName)?.value;

    this.refreshSmevStatus({applicationId, smevRequestType});
  }

  public showInfoSysWarning(evt: any) {
    this.$modal.show("dialog", {
      title: "ВНИМАНИЕ",
      text: 'При добавлении опции "Для информационной системы" сгенерированный в дальнейшем запрос будет обезличенным.\
      На этапе загрузки запроса потребуется загрузить файл обезличенного запроса',
      buttons: [
        {
          title: "ОТМЕНА",
          handler: () => {
            this.inputHandler({target: {name: "forInfoSys", type: "change", value: false}});
            this.$modal.hide("dialog");
          }
        },
        {
          title: "ПРОДОЛЖИТЬ",
          default: true,
          handler: () => {
            this.inputHandler(evt);
            this.$modal.hide("dialog");
          }
        }        
      ]
    });
  }
}
