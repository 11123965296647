<template>
  <div class="input-controls-wrapper">
    <div v-if="statusSMEV" :class="['input-controls-status-smev', `status_${statusSMEV.status}`]">
      <div
        class="input-controls-status-smev-control"
        @mouseenter="showTooltipStatusSMEV=true"
        @mouseleave="showTooltipStatusSMEV=false"
      >
        <span v-if="statusSMEV.status == -2">?</span>
        <span v-else-if="statusSMEV.status == -1">&#10008;</span>
        <span v-else-if="statusSMEV.status == 0 || statusSMEV.status == 2">&bull;&bull;&bull;</span>
        <span v-else-if="statusSMEV.status == 1">&#10004;</span>
      </div>
      <transition name="fade">
        <div v-if="showTooltipStatusSMEV" class="input-controls-tooltip status-smev">
          <span>{{statusSMEV.comment}}</span>
        </div>
      </transition>
    </div>
    <div v-if="false && buttonHandler && {}.toString.call(buttonHandler) === '[object Function]'" class="input-controls-btn">
      <button
        class="input-controls-btn-control"
        type="button"
        @mouseenter="showTooltipButton=true"
        @mouseleave="showTooltipButton=false"
        @click.prevent="buttonHandler"
      >&#8635;</button>
      <transition name="fade">
        <div v-if="showTooltipButton" class="input-controls-tooltip btn">
          <span>Переотправить</span>
        </div>
      </transition>
    </div>
    <div v-if="info" class="input-controls-info">
      <div
        class="input-controls-info-control"
        @mouseenter="showTooltipInfo=true"
        @mouseleave="showTooltipInfo=false"
      >&quest;</div>
      <transition name="fade">
        <div
          v-if="showTooltipInfo"
          class="input-controls-tooltip info"
          @mouseenter="showTooltipInfo=true"
          @mouseleave="showTooltipInfo=false"
        >
          <span>{{info}}</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: "InputControls",
    props: {
      statusSMEV: {
        type: Object,
        default: null,
        required: false
      },
      buttonHandler: {
        type: Function,
        default: null,
        required: false
      },
      info: {
        type: String,
        default: "",
        required: false
      }
    },
    data() {
      return {
        showTooltipStatusSMEV: false,
        showTooltipButton: false,
        showTooltipInfo: false
      }
    },
  }
</script>

<style lang="scss">
  .input-controls-wrapper {
    display: flex;
    width: 120px;
  }

  .input-controls-status-smev {
    position: relative;
    height: 30px;
    width: 30px;
    padding-top: 3px;
    text-align: center;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    color: rgb(255, 255, 255);
    cursor: default;

    &.status_-1 {
      border-color: rgb(245, 56, 59);
      background-color: rgba(245, 56, 59, 0.7);
    }
    &.status_1 {
      border-color: rgb(16, 180, 135);
      background-color: rgba(16, 180, 135, 0.7);
    }
    &.status_0, &.status_2 {
      border-color: rgb(204, 204, 0);
      background-color: rgba(204, 204, 0, 0.7);
    }
    &.status_-2 {
      border-color: rgb(128, 128, 128);
      background-color: rgb(246, 246, 248);
      color: rgb(128, 128, 128);
    }
  }

  .input-controls-btn {
    position: relative;

    button{
      height: 30px;
      width: 30px;
      margin-left: 15px;
      text-align: center;
      border-radius: 15px;
      border: none;
      color: rgb(255, 255, 255);
      background-color: rgb(111, 97, 233);
      font-size: 1.3em;
      cursor: pointer;
    }
  }

  .input-controls-info {
    position: relative;

    &-control{
      height: 30px;
      width: 30px;
      text-align: center;
      padding-top: 2px;
      border-radius: 15px;
      border: none;
      color: rgb(111, 97, 233);
      background-color: rgba(111, 97, 233, 0.1);
      border: 1px solid rgba(111, 97, 233, 0.5);
      font-size: 1.3em;
      cursor: default;
    }

    &-control:not(:nth-child(1)){
      margin-left: 15px;
    }
  }

.input-controls-tooltip {
    position: absolute;
    top: 50%;
    right: -15px;
    z-index: 3;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    max-width: 180px;
    min-width: 140px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    &.status-smev {
      max-width: 360px;
    }
    &.btn {
      min-width: 120px;
    }
    &.info {
      max-width: 360px;
    }
    &.fade-enter-active,
    &.fade-leave-active {
      transition: 0.3s ease;
      transition-property: opacity, transform;
    }
    &.fade-enter,
    &.fade-leave-to {
      transform: translate(120%, -50%);
      opacity: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
  }
</style>
