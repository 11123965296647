















































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions, mapState } from "vuex";
import { TweenLite } from "gsap";
import anime from "animejs";
import { isEmpty } from "lodash";

import InputSwitch from "@/components/UI/form/InputSwitch.vue";
import Form from "@/components/UI/form/Form.vue";
import Button from "@/components/UI/Button.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import Radio from "@/components/UI/form/Radio.vue";
import ModalWithField from "@/components/ModalWithField.vue";

import { ISubitem, IProduct } from "@/store/modules/products/products.interfaces";
import { numberFormat } from "@/helpers/numberFormat";

@Component({
  computed: {
    ...mapState("application", ["isEdit","isSubmited", "formControls",]),
    ...mapState("productsState", ["products", "productsSelected"]),
    ...mapState("cert", ["cert"]),
    ...mapGetters("productsState", [
      "getHasProductsWithEDM",
      "getProductsSelectedWithEDM",
      "getHasTariffFNS"
    ]),
    ...mapGetters("application", [
      "getVisibleBodyForm",
      "getApplicationInfo",
      "getIsFormValid",
      "getTypeEntreValue"
      ])
  },
  methods: {
    ...mapActions("productsState", [
      "fetchProducts",
      "toggleProductsSelected",
      "removeProductById"
    ]),
    ...mapActions("application", [
      "submitHandler",
      "inputHandler",
      "setFormControlProps",
      "setNewCertRequest"
    ])
  },
  components: { InputSwitch, Form, Button, Checkbox, Radio, ModalWithField }
})
export default class Products extends Vue {
  /* data */
  private tl!: TweenLite;
  private subitemOpen: { [key:number]:boolean } = {};

  /* computed */
  public cert!: string | null;
  private products!: IProduct[];
  public isEdit!: null | boolean;
  
  public getHasTariffFNS!: boolean;
  public isSubmited!: null |boolean;
  public getTypeEntreValue!: string;
  private productsSelected!: number[];
  private getHasProductsWithEDM!: boolean;
  private getProductsSelectedWithEDM!: IProduct[];

  private isProductChecked(productID: number) {
    return this.productsSelected.some(id => id === productID);
  }

  private isProductDisabled(product: IProduct, certType:string){    
    if (certType === "fl"){
      if (product.name.includes("юридического лица на ПАК УЦ ФНС России")){
        return true;
      }
    }

    if (certType !== "fl" && product.name.includes("проверки электронной подписи (КСКПЭП) физического лица")){
      return true;
    }

    return false;
  }

  private getPrice(price: {[key: string]: string}) {
    return this.getTypeEntreValue
      && !isEmpty(this.getTypeEntreValue)
      && price.hasOwnProperty(this.getTypeEntreValue)
      ? `${numberFormat(Number(price[this.getTypeEntreValue]))} ₽`
      : "";
  }

  private getResultPrice(typeEntre: string) {
    return this.productsSelected.reduce((sum, id) => {
      const product: IProduct | undefined = this.products.find(p => p.id === id);
      if (product && product.price) {
        return sum + Number(product.price[typeEntre]);
      }
      return sum;
    }, 0);
  }

  /* methods */
  public fetchProducts!: ({type}: {type: "app" | "preapp"}) => void;
  public submitHandler!:  () => void;
  public removeProductById!: (id: number) => void;
  public setNewCertRequest!: (certRequest: any) => void;
  public setFormControlProps!: ({name, props}: any) => void;
  public toggleProductsSelected!: ({id, checked}: {id: number;checked: boolean;}) => void;
  
  public animateSubitem() {
    this.products.forEach(({id, subitems}) => {
      if (subitems) {
        const isProductChecked = this.isProductChecked(id);
        const elem = (this.$refs[id] as (Element & { isHide: boolean })[])[0];
        const elementSubitems = elem.querySelector(
          ".product-form-table-item__subitems"
        );

        if (elem && elem.isHide !== isProductChecked) {
          const isOpen = elem.isHide = isProductChecked;

          const tl = new TweenLite(elementSubitems, 0.8, {
            height: isOpen ? "auto" : 0
          })

          tl.eventCallback("onStart", () => {
            elem.classList.add("is-animation");
          });

          tl.eventCallback("onComplete", () => {
            elem.classList.remove("is-animation");

            if (isOpen) {
              elem.classList.remove("is-hidden");
            } else {
              elem.classList.add("is-hidden");
            }
          });
        }
      }
    });
  }

  public result(animate: boolean = true) {
    const elemResult: any = this.$refs["result-price"];

    if (elemResult) {
      if (animate) {
        this.tl = new TweenLite(elemResult, 1, {
          roundProps: "innerText",
          innerText: this.getResultPrice(this.getTypeEntreValue)
        });
      } else {
        elemResult.innerText = this.getResultPrice(this.getTypeEntreValue);
      }
    }
  }

  private handlerChange(id: number, subitems: ISubitem[]) {
    subitems.forEach(subitem => {
      this.toggleProductsSelected({ id: subitem.id, checked: false });
    });

    this.toggleProductsSelected({ id, checked: true });
  }

  private handleOkCodeFNS(value: string) {
    this.setFormControlProps({
      name: "fnsCode",
      props: {
        active: true,
        required: true,
        value,
        rules: {
          empty: true,
          minLength: 4
        },
      }
    });
  }

  private handleCancelCodeFNS() {
    this.getProductsSelectedWithEDM.forEach((p: IProduct) => {
      this.removeProductById(p.id);
    })
  }

  private createOrSaveClickHandler() {
    if (this.cert && this.getHasTariffFNS) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "В заявке присутствует тариф ФНС.<br />" + 
              "В процессе сохранения новой заявки будет предложено подписать файл запроса",
        buttons: [
          // {
          //   title: "Отмена",
          // },
          {
            title: "Продолжить",
            handler: () => {
              this.setNewCertRequest(this.cert);
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    } else {
      this.submitHandler();
    }
  }

  /* watchers */
  @Watch("getHasProductsWithEDM")
  watcherGetHasProductsWithEDM() {
    if (this.getHasProductsWithEDM) {
      this.$modal.show("modal-code-fns");
    } else {
      this.setFormControlProps({
        name: "fnsCode",
        props: {value: "", active: false, required: false}
      });
    }
  }

  /* life circle methods */
  public created() {
    if (this.isEdit === null && this.$route.params.orderId === undefined) {
      this.fetchProducts({type: "app"});
    }
  }

  public mounted() {
    this.animateSubitem();
    this.result(false);
  }

  public beforeDestroy() {
    if (this.tl) {
      this.tl.kill();
    }
  }

  public updated() {
    this.result(true);
    this.animateSubitem();
  }
}
