






























import { Vue, Component, Prop, Model } from "vue-property-decorator";

interface IValidationProps {
  valid: boolean;
  touched: boolean;
  shouldValidate: boolean;
}

@Component({})
export default class TextArea extends Vue {
  @Model("input") public readonly value!: boolean;
  @Prop({ type: String }) public label!: string;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop({ type: String }) public id!: string;
  @Prop({ type: String }) public name!: string;
  @Prop({ type: Boolean }) public touched!: boolean;
  @Prop({ type: Boolean }) public shouldValidate!: boolean;
  @Prop({ type: Boolean }) public valid!: boolean;
  @Prop({ type: String }) public placeholder!: string;
  @Prop({ type: Array, default: () => [] }) public errors!: [];
  @Prop({ type: String }) public invalidityInfo?: string;
  @Prop({ type: Boolean, default: true }) public hasLabel?: boolean;

  get inputID(): string {
    return (
      this.id || `${this.name}-${(Number(Math.random().toFixed(3)) * 100) ^ 0}`
    );
  }

  public isValidation = ({
    valid,
    touched,
    shouldValidate
  }: IValidationProps) => {
    return !valid && touched && shouldValidate;
  };

  public inputHandler(evt: any) {
    this.$emit("input", evt.target.value);
    this.$emit("change", evt);
  }
}
