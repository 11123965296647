<template>
  <modal 
    :name="name"
    styles="border-radius: 12px;"
    :width="width"
    :height="height"
    :adaptive="true"
    :clickToClose="false"
  >
    <div class="modal-with-fields-wrapper">
      <div class="modal-with-fields-title">{{title}}</div>
      <InputRadio
        v-for="field in fields.filter(f => f.type === 'radio')"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :val="field.options"
        @change="onChange"
      />
      <TextArea
        v-for="field in fields.filter(f => f.type === 'textarea')"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        @change="onChange"
      />
      <Select
        v-for="field in fields.filter(f => f.type === 'select')"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        :options="field.options"
        @change="onChange"
      />
      <Input
        v-for="field in fields.filter(f => f.type === 'input')"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :mask="field.mask"
        :valid="true"
        :shouldValidate="false"
        :value="values[field.name]"
        @change="onChange"
      />
      <Checkbox
        v-for="field in fields.filter((f) => f.type === 'switch')"
        :key="field.name"
        :name="field.name"
        :label="field.label"
        :valid="true"
        :shouldValidate="false"
        :checked="values[field.name]"
        @change-event="onChange"
      />
      <div class="modal-with-fields-description">
        <span>{{description}}</span>
      </div>
      <div class="modal-with-fields-buttons">
        <Button @click="handleCancel">Отмена</Button>
        <Button @click="handleOk" :disabled="isOkDisabled">Ok</Button>
      </div>
    </div>
  </modal>
</template>

<script>
  import Vue from "vue";

  import Button from "@/components/UI/Button.vue";
  import Select from "@/components/UI/form/Select.vue";
  import TextArea from "@/components/UI/form/TextArea.vue";
  import InputRadio from "@/components/UI/form/InputRadio.vue";
  import Input from "@/components/UI/form/Input.vue";
  import Checkbox from '@/components/UI/form/Checkbox.vue';
  import { isUndefined } from "lodash";

  export default Vue.extend({
    name: "ModalWithFields",

    components: {Button, InputRadio, TextArea, Select, Input, Checkbox},

    props: {
      name: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: false,
        default: ""
      },
      fields: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        values: this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.defaultValue
        }), {})
      }
    },

    computed: {
      isOkDisabled() {
        const isValids = Object.keys(this.values).reduce((result, name) => {
          const value = this.values[name];
          const isRequired = this.getRules[name] ? this.getRules[name].isRequired : true;
          
          const isValid = !!(value === 0 || value) || !isRequired;
          return [...result, isValid];
        }, []);

        const isValuesEmpty = !Object.values(this.values).filter(v => (v === 0 || !!v)).length;
        return isValuesEmpty || isValids.includes(false);
      },

      getRules() {
        return this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.rule
        }), {})
      }
    },

    methods: {
      handleOk() {
        this.$emit("onClickOk", {...this.values});
        this.cleanValues();
        this.$modal.hide(this.name);
      },

      handleCancel() {
        this.$emit("onClickCancel", {...this.values});
        this.cleanValues();
        this.$modal.hide(this.name);
      },

      onChange(evt) {
        const {value, checked, name} = evt.target;

        if (!isUndefined(checked)) {
          this.values = {...this. values, [name]: checked};
        } else {
          this.values = {...this. values, [name]: value};
        }
      },

      cleanValues() {
        this.values = this.fields.reduce((obj, item) => ({
          ...obj,
          [item.name]: item.defaultValue
        }), {})
      }
    },
  })
</script>

<style lang="scss">
  .modal-with-fields-wrapper {
    width: 100%;
    padding: 45px;
    text-align: center;

    .form-item-radio {
      width: 50%;
    }
  }
  .modal-with-fields-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 25px;
  }
  .modal-with-fields-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
  .modal-with-fields-description {
    text-align: justify;
    padding: 25px 0 10px 0;
  }
</style>
