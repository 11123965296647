



































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";

import { InitialApplicationStatuses } from "@/constants/InitialApplicationStatuses";
import { InitialFileTypes } from "@/constants/InitialFileTypes";

import ItemDocument from "@/components/ItemDocument.vue";
import Button from "@/components/UI/Button.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Download from "@/components/UI/icon/Download.vue";
import { IFieldUpload } from "@/store/modules/application/application.interfaces";

@Component({
  computed: {
    ...mapState("application", [
      "isEditUpload",
      "statusId",
      "orderId",
      "isDocumentsCollected",
      "currentCertThumbprint",
      "isUploadFormLoaded",
      "errorMessage",
      "managerId",
      "checkCertInFnsMessage",
    ]),
    ...mapState("applicationListState", ["isArchive"]),
    ...mapGetters("application", ["getIsDocumentsReadyToUpload"]),
    ...mapGetters("authorizationState", ["isManagerPartner"])
  },
  methods: {
    ...mapActions("application", [
      "downloadHandler",
      "handlerSaveDocuments",
      "inputFileHandler",
      "setCurrentCertThumbprint",
      "fotoCaptureHandler",
      "handlerHeadSign",
      "clearcheckCertInFnsMessage",
      "enableRevocation",
      "signWithIgnore",
      "fetchAnotherUploads",
      "fetchAnotherUpload",
      "transferScansFromPreApp",
      "uploadApplicantSignedDocuments",
      "getApplicantSignedArchiveWithDocs",
      "getApplicantSignedDocument",
      "getApplicantSignedDocsListFromArchive"
    ])
  },
  components: { ItemDocument, Button, Download, IconBase }
})
export default class ProposalFormDocument extends Vue {
  @Prop({ type: String }) public title!: string;
  @Prop({ type: String }) public descr!: string;
  @Prop({ type: Boolean, default: false}) public typeDownload!: boolean;
  @Prop({ type: Boolean, default: false}) public typeUpload!: boolean;
  @Prop({ type: Boolean, default: false}) public typeCollected!: boolean;
  @Prop({ type: Boolean, default: false}) public typeAnother!: boolean;
  @Prop({ type: Array, default: () => [] }) public documents!: [];

  private downloadHandler!: (docInfo: {
    orderId: number;
    userId: number;
    typeId: number;
  }) => void;
  private fotoCaptureHandler!: (cameraimg: any) => void;
  private statusId!: number;
  private orderId!: number;
  private managerId!: number;
  private clearcheckCertInFnsMessage!: () => void;
  private fetchAnotherUploads!: () => void;
  private fetchAnotherUpload!: (name: string) => void;
  private transferScansFromPreApp!: () => void;
  private getApplicantSignedArchiveWithDocs!: () => void;
  private getApplicantSignedDocument!: (fileType: number) => void;
  private getApplicantSignedDocsListFromArchive!: () => void;

  private isUploadFormLoaded!: boolean;
  private modalErrorClose = false;

  private get isDocumentsSending(): boolean {
    return this.statusId === InitialApplicationStatuses.DocumentsSending;
  }

  private get isHeadManagerSignWaiting(): boolean {
    return this.statusId === InitialApplicationStatuses.HeadManagerSign;
  }

  public mounted() {
    if (this.typeUpload) {
      this.transferScansFromPreApp();
    }
    if (this.typeCollected) {
      this.transferScansFromPreApp();
      //this.getApplicantSignedDocsListFromArchive();
    }
    if (this.typeAnother) {
      this.fetchAnotherUploads();
    }
  }

  public beforeCloseCheckCertInFNS(evt: any) {
    this.modalErrorClose = true;

    this.clearcheckCertInFnsMessage();

    if (!this.isUploadFormLoaded) {
      evt.stop();
    }
  }

  private dowanloadDocumentZip() {
    const hasExtra = this.documents.some((e: IFieldUpload) => e.isExtra && e.isValid);
    const hasSignedDocs = this.documents.some((e: IFieldUpload) =>
      (e.fileType === InitialFileTypes.ApplicantSignedPrintingForm ||
      e.fileType === InitialFileTypes.ApplicantSignedSecurityInstructionForm ||
      e.fileType === InitialFileTypes.PrintingFormSignature) &&
      e.isCollected);

    this.downloadHandler({
      orderId: this.orderId,
      userId: this.managerId,
      typeId: InitialFileTypes.DocumentsZip
    });

    hasExtra && this.downloadHandler({
      orderId: this.orderId,
      userId: this.managerId,
      typeId: InitialFileTypes.ExtraDocs
    });

    hasSignedDocs && this.getApplicantSignedArchiveWithDocs();
  }

  private onFotoCapture(cameraimg: any) {
    console.log("foto saving...");
    this.fotoCaptureHandler(cameraimg);
    // this.$modal.hide("webcam_modal");
  }

   private async handleClickItemDocument(item: any) {
    if (item.name.startsWith("another")) {
      this.fetchAnotherUpload(item.name);
    } else if (["certificateSigned", "securityInformationSigned"].includes(item.name) && item.isCollected) {
      this.getApplicantSignedDocument(item.fileType);
    } else if (item.files && item.files.length) {
      const url = URL.createObjectURL(item.files[0]);
      window.open(url, '_blank');
    }
  }
}
