









import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "PageLayout" })
export default class PageLayout extends Vue {}
