










import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class DocKey extends Vue {}
