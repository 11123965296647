
















import { Vue, Component } from "vue-property-decorator";
import {  mapState, mapMutations, mapGetters, mapActions } from "vuex";

@Component({ name: "Header",
  computed:{
    ...mapGetters("application",["newPreAppNum"]),
  } })
export default class Header extends Vue {
  public newPreAppNum!: number;
}
