export enum UpdRequestStatuses {
    // не сформирован
    NotSended = 0,
  
    // запрос сформирован
    RequestCreated = 10,
  
    // запрос отправлен
    RequestSended = 20,
  
    // документ сформирован в 1С
    FormedIn1C = 30,
  
    // УПД получен
    UPDSuccess = 40,
  
    //Ошибка/Отказ
    UPDFailure = 50
  }