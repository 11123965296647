








import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class FormItem extends Vue {
  @Prop() public label!: string;
  @Prop() public stype!: string;

  get setStype() {
    return this.stype !== undefined ? this.stype : "normal";
  }
}
