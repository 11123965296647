export enum ORGANIZATION_TYPES {
  Undefined = 13, // [Description("Неопределенный")]
  Agential = 1, // [Description("Агентский")]
  Partnership = 2, // [Description("Партнерский")]
  Tensor = 3, // [Description("ТЕНЗОР")]
  Contour = 4, // [Description("КОНТУР")]
  Itcom = 5, // [Description("АЙТИКОМ")]
  Kaluga = 6, // [Description("КАЛУГА")]
  Taxcom = 7, // [Description("ТАКСКОМ")]
  ElectronicExpress = 8, // [Description("Электронный экспресс")]
  Corporate = 9, // [Description("Корпоративный")]
}
