















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconBase extends Vue {
  @Prop({ type: String, default: "box" }) public iconName!: string;
  @Prop({ type: [Number, String], default: 18 }) public width!: string | number;
  @Prop({ type: [Number, String], default: 18 }) public height!:
    | string
    | number;
  @Prop({ type: String, default: "0 0 18 18" }) public viewBox!: string;
  @Prop({ type: String, default: "currentColor" }) public iconColor!: string;
}
