
























import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";

interface RadioButtonItem {
  active: boolean;
  value: string;
  label: string;
  valid?: boolean;
  touched?: boolean;
  shouldValidate?: boolean;
}

@Component({})
export default class InputRadio extends Vue {
  @Model("change") public readonly value!: boolean;
  @Prop({ type: String, default: null }) public name!: string | null;
  @Prop({ type: String, default: undefined }) public label!: string;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop({ type: Array, default: [] }) public val!: RadioButtonItem[];
  @Prop({ type: Boolean, default: true }) public valid!: boolean;
  @Prop({ type: Boolean, default: false }) public touched!: boolean;
  @Prop({ type: Boolean, default: false }) public shouldValidate!: boolean;

  public inputValue = this.value;

  public updateInput(evt: any) {
    this.$emit("change", evt);
  }

  private get invalid() {
    return !this.valid && this.touched && this.shouldValidate;
  };

  @Watch("inputValue")
  private onValueChanged(newValue: string, oldValue: string) {
    this.$emit("input", newValue);
  }
}
