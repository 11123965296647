

















import { Vue, Component, Prop, Model, Watch } from "vue-property-decorator";
import Tab from "./Tab.vue";

@Component({ name: "Tabs" })
export default class Tabs extends Vue {
  @Model("change", { type: Number, default: 1 })
  private readonly tabIndex!: number;

  private tabs: Tab[] = [];
  private activeTabIndex: number = this.tabIndex;

  public created() {
    this.tabs = this.$children as Tab[];
  }

  public mounted() {
    this.handlerSelectTab(this.activeTabIndex);
  }

  private handlerSelectTab(id: number | string) {
    const selectTab = this.findTab(id);

    if (!selectTab || !selectTab.element || selectTab.element.disabled) {
      return;
    }

    this.tabs.forEach((tab, idx) => {
      tab.isActive = selectTab.element.title === tab.title;
    });

    this.$emit("change", (this.activeTabIndex = selectTab.index + 1));
  }

  private findTab(id: number | string): null | { index: number; element: Tab } {
    switch (typeof id) {
      case "number":
        return {
          index: id - 1,
          element: this.tabs[id - 1]
        };

      case "string":
        const idx = this.tabs.findIndex(({ title }) => title === id);

        return {
          index: idx,
          element: this.tabs[idx]
        };
      default:
        return null;
    }
  }

  @Watch("tabIndex")
  private currentTab(newValue: number) {
    if (newValue !== this.activeTabIndex) {
      this.handlerSelectTab(newValue);
    }
  }
}
